<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.users-activities') }}
      </h2>
    </div>

    <box>
      <data-table
        ref="table"
        sort-by="created_at"
        sort-desc
        :items="items"
        :theme="$_ui_theme_tables"
        :per-page="20"
      >
        <data-column
          field="created_at"
          :label="$t('time')"
        >
          <template slot-scope="{ value }">
            <span>{{ value | dmyhs }}</span>
          </template>
        </data-column>
        <data-column
          field="user.email"
          :label="$t('user')"
        />
        <data-column
          field="ip_address"
          label="IP"
        />
        <data-column
          field="description"
          :label="$t('description')"
        />
        <data-column
          field="method"
          label="Method"
        />
        <data-column
          field="endpoint"
          :label="$t('endpoint')"
        />
        <data-column
          field="status"
          :label="$t('status')"
        />
      </data-table>
    </box>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'

export default {
  components: { DataTable, DataColumn },
  computed: {
    language () {
      return this.$store.state.ui.language
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    }
  },
  methods: {
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/users/activities?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    }
  }
}
</script>
