<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.users-all') }}
      </h2>
      <p>
        <button
          class="button is-primary"
          @click.prevent="create"
        >
          <octicon :icon="plus" /> <span>{{ $t('create') }}</span>
        </button>
      </p>
    </div>

    <box>
      <data-table
        ref="table"
        :items="items"
        :theme="$_ui_theme_tables"
      >
        <data-column
          field="id"
          label="ID"
        />
        <data-column
          field="email"
          label="Email"
        />
        <data-column
          field="scopes"
          :label="$t('scopes')"
        >
          <template slot-scope="{ value }">
            <span
              v-for="channel in value"
              :key="channel"
              class="px-2 py-1 tag is-info is-light is-uppercase"
            >{{ channel }}</span>
          </template>
        </data-column>
        <data-column
          field="created_at"
          :label="$t('time')"
        >
          <template slot-scope="{ value }">
            <span>{{ value | dmyhs }}</span>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')"
          :sortable="false"
        >
          <template slot-scope="props">
            <button
              v-show="can(`update:user:${props.item.id}`)"
              class="button is-small is-warning is-light mr-2"
              @click.prevent="edit(props.item)"
            >
              <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
            </button>

            <button
              v-show="can(`update:user:${props.item.id}`)"
              class="button is-small is-danger is-light"
              @click.prevent="destroy(props.item)"
            >
              <octicon :icon="trashcan" /> <span>{{ $t('delete') }}</span>
            </button>
          </template>
        </data-column>
      </data-table>
    </box>

    <modal v-if="item" :show.sync="modal" content-width="800px">
      <form @submit.prevent="submit">
        <box>
          <div slot="header">
            <span v-if="item.id">{{ $t('editUser') }}</span><span v-else>{{ $t('newUser') }}</span> <span v-if="item.email">-</span> {{ item.email }}
          </div>

          <template v-if="step === 1">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  v-model="item.email" class="input" type="email"
                  :placeholder="$t('yourEmail')"
                  :disabled="item.id"
                  required
                >
              </div>
            </div>

            <div v-if="item.email === user.id()" class="field">
              <label class="label">{{ $t('oldPassword') }}</label>
              <div class="control">
                <input
                  v-model="item.old_password" class="input" type="password"
                  :placeholder="$t('oldPassword')"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('password') }}</label>
              <div class="control">
                <input
                  v-model="item.password" class="input" type="password"
                  :placeholder="$t('password')"
                  required
                >
              </div>
            </div>
          </template>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </box>
      </form>
    </modal>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
import { pencil, plus, trashcan } from 'octicons-vue'
import { Modal } from '@cyradar/ui'

export default {
  components: { DataTable, DataColumn, Modal },
  data () {
    return {
      modal: false,
      item: null,
      step: 1
    }
  },
  computed: {
    plus () {
      return plus
    },
    trashcan () {
      return trashcan
    },
    pencil () {
      return pencil
    },
    user () {
      return this.$store.getters['authentication/user']
    },
    language () {
      return this.$store.state.ui.language
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.item.current_user = this.user.id()
        this.update()
        return
      }

      this.store()
    },
    resetItem () {
      this.step = 1
      this.item = {
        email: '',
        old_password: '',
        password: '',
        current_user: '',
        scopes: ['*']
      }
    },
    create () {
      this.resetItem()
      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    store () {
      return this.$http.post('/api/v1/users', this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: this.$t('users.userCreated')
          })

          this.modal = false
          this.resetItem()
        })
    },
    update () {
      return this.$http.patch(`/api/v1/users/${this.item.id}`, this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: this.$t('users.userUpdated')
          })

          this.modal = false
          this.resetItem()
        })
    },
    destroy (x) {
      return this.$http.delete(`/api/v1/users/${x.id}`)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: this.$t('users.userDeleted') + ' ' + x.email
          })
        })
    },
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/users?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    }
  }
}
</script>
